.hero {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  position: relative;
}

.hero__content {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;
  backdrop-filter: blur(4px);
}

.hero__logo {
  /* position: absolute;
  top: 40px;
  left: auto;
  right: auto; */
}

.hero__header {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 96px;
  line-height: 112px;
  text-align: center;
  color: #ffffff;
  text-shadow: -4px 4px 0px #19e4cc;
}
.hero__subText {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 112px;
  text-align: center;
  color: #ffffff;
}
.hero__text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  text-align: center;
  color: #ffffff;
}

.hero__btn--wrapper {
  display: flex;
  gap: 32px;
}

.hero__btn {
  padding: 20px 48px;
  background: #ffffff;
  box-shadow: 0px 5.56291px 5.56291px rgba(0, 0, 0, 0.25);
  border-radius: 183.576px;
  color: #000;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  text-decoration: none;
  /* margin-top: 40px; */
}

.hero__video {
  object-fit: cover;
  width: 100%;
  height: 100dvh;
  z-index: 10;
}

.marquee {
  height: 100px;
}

.marquee--logos {
  width: 200px;
  /* height: 100px; */
  height: auto;
  margin: 0px 70px;
  /* background-position: center; */
  background-size: cover;
}

.generic_title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 100px;
  letter-spacing: -2px;
  color: #19e4cc;
  text-align: left;
}

.solutions,
.components,
.intro,
.faq {
  padding: 0px 160px;
}

.solutions__content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 72px;
  margin-top: 48px;
}

.solutions--content__item {
  align-self: stretch;
  flex: 1 1 0;
}

.solutions--content--item__title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -2px;
  color: #ffffff;
  text-align: left;
  margin-bottom: 34px;
}

.solutions--content--item__content {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
}

.solutions--content--item__content h6 {
  font-weight: 800;
  margin-bottom: 10px;
  font-size: 20px;
}

.components__diagram {
  margin-top: 48px;
}

.components__content {
  display: none;
  flex-direction: column;
  margin-top: 48px;
}

.components--content__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 32px;
}

.components--content__item:last-child {
  margin-bottom: 0px;
}

.components--content__item h3 {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.03em;
  color: #ffffff;
  padding: 0;
  text-align: center;
}

.components--content__item h3 img {
  width: 45px;
  height: auto;
  margin-right: 10px;
}

.components--content__item ul {
  background-color: #2a2b2d;
  box-shadow: 0 3px 26px rgba(0, 0, 0, 0.16);
  padding: 20px 35px;
  width: 100%;
  border-radius: 12px;
}

.components--content__item ul li {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #ffffff;
  list-style-position: inside;
  margin-bottom: 10px;
}

.intro__content {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-top: 48px;
}

.intro__content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.muofit {
  width: 100%;
  padding: 120px 160px;
  display: flex;
  flex-direction: column;

  background-image: url("assets/img/mu.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-color: #282828;
}

.muofit__outlineText {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 80px;
  text-align: left;
  letter-spacing: -0.03em;
  margin-bottom: 20px;
  text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff,
    -1px -1px 0 #fff;
  color: #1e1f21;
}

.muofit__normalText {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 80px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #19e4cc;
}

.muofit__contact_wrapper {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: 64px;

  margin-top: 64px;
}

.muofit__contact_wrapper a {
  display: grid;
  place-items: center;
}

.muofit__email {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 32px;
}

.social {
  display: flex;
  align-items: center;
  flex-flow: row-reverse;
  gap: 24px;

  padding: 32px 160px 0px 0px;
}

.social a img {
  width: 35px;
  height: 35px;
}

.footer {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 20px 160px 32px 160px;
}

.footer--menu__item {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-left: 20px;
  text-decoration: none;
}

.footer--menu__item:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 1250px) {
  .components__diagram {
    display: none;
  }

  .components__content {
    display: flex;
  }

  .muofit__contact_wrapper {
    grid-template-columns: 1fr;
  }

  .footer {
    flex-direction: column-reverse;
  }

  .footer__menu {
    text-align: center;
  }

  .social {
    justify-content: center;
    padding: 32px 0px 0px 0px;
  }
}

@media only screen and (max-width: 1400px) {
  .hero__header {
    font-size: 60px;
    line-height: 66px;
    padding: 0 50px;
  }
  
  .hero__subText{
    font-size: 36px;
    line-height: 30px;
  }

  .hero__text{
    font-size: 20px;
    line-height: 30px;
  }

}

@media only screen and (max-width: 810px) {


  .hero__btn--wrapper {
    flex-direction: column;
  }

  .hero__btn {
    padding: 10px 40px;
    font-size: 20px;
    line-height: 30px;
  }

  .solutions,
  .components,
  .intro,
  .faq {
    padding: 0px 25px;
  }

  .generic_title {
    font-size: 64px;
    line-height: 74px;
  }

  .solutions__content {
    flex-direction: column;
  }

  .components__title {
    line-height: 50px;
    padding: 60px 30px;
    text-align: left;
  }

  .muofit {
    padding: 60px 30px;
    background-size: 70%;
  }

  .muofit__email,
  .muofit__contact_wrapper {
    grid-template-columns: 1fr;
  }

  .muofit__email button {
    max-width: 100%;
  }

  .muofit__outlineText {
    font-size: 24px;
    line-height: 24px;
  }

  .muofit__normalText {
    font-size: 24px;
    line-height: 24px;
  }

  .muofit__social {
    gap: 50px;
    margin-top: 30px;
  }

  .muofit__social a img {
    width: 50px;
    height: 50px;
  }

  .footer {
    align-content: center;
    justify-content: center;
    /* padding-top: 0; */
  }

  .footer__menu {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    margin-bottom: 50px;
  }

  .footer--menu__item {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
